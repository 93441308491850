import * as StringUtils from './string.utils'
import * as DateUtils from './date.utils'
import * as PermissionUtils from './permissions.utils'
import * as NumberUtils from './number.utils'

// allow to print console logs only on dev mode
export function debugLog(message, ...optionalParams) {
  if (process.env.REACT_APP_APP_ENV === 'development') {
    console.log('### ' + message, ...optionalParams)
  }
}
export function debugErrorLog(message, ...optionalParams) {
  if (process.env.REACT_APP_APP_ENV === 'development') {
    console.error('!!! ' + message, ...optionalParams)
  }
}

export { StringUtils, DateUtils, PermissionUtils, NumberUtils }
