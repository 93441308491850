import React, { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useAppStore from 'core/store/app.store'
import AuthProvider from 'data/providers/auth.provider'
import { debugLog } from 'core/utils'
import useThemeStore, { keyStorageTheme } from 'core/store/theme.store'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'
import { useColorModes } from '@coreui/react'
import { apiService } from 'core/services/http.service'

const AuthContext = createContext()

//  Provider component that wraps your app and makes
export const useAuth = () => useContext(AuthContext)

//  Provider component that wraps your component to provide user data
export const AuthContextProvider = (props) => {
  const { colorMode, isColorModeSet, setColorMode } = useColorModes(keyStorageTheme)
  const [auth, setAuth] = useState(AuthProvider.user)
  const { isLoading, setLoading, setConfigs } = useAppStore()
  const { setTheme, setPersistThemeFn } = useThemeStore()
  const { i18n } = useTranslation()
  const queryClient = useQueryClient()

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
    setConfigs({ language: language })
    apiService.setLanguage(language)
  }

  useEffect(() => {
    // check the theme and set it as the app starts
    if (isColorModeSet()) {
      if (auth && auth.preferredTheme !== colorMode) {
        setColorMode(auth.preferredTheme)
        setTheme(auth.preferredTheme)
      } else {
        setTheme(colorMode)
      }
      setPersistThemeFn(setColorMode)
    }
    if (auth) {
      if (auth.preferredLanguage !== i18n.language) {
        changeLanguage(auth.preferredLanguage)
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  // Make login request
  const login = async (data) => {
    let currentAuth = AuthProvider.user
    if (currentAuth === null) return
    setAuth(currentAuth)
    setTheme(currentAuth?.preferredTheme)
    changeLanguage(currentAuth?.preferredLanguage)
  }

  const invalidatePermissions = () => {
    queryClient.invalidateQueries({ queryKey: ['auth', 'permissions'] })
    debugLog('Query auth permissions invalidated')
  }

  // Make logout request
  const logout = async () => {
    let currentAuth = AuthProvider.user
    if (currentAuth === null) return setAuth(null)
    if (!isLoading) setLoading(true)
    await AuthProvider.logout()
      .then(() => {
        setAuth(null)
        debugLog('Auth session destroyed')
      })
      .finally(() => {
        if (isLoading) setLoading(false)
      })
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        logout,
        login,
        AuthProvider,
        invalidatePermissions,
      }}
    >
      <React.Fragment>{props.children}</React.Fragment>
    </AuthContext.Provider>
  )
}

AuthContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  onlyIfAuthenticated: PropTypes.bool,
  onlyIfNotAuthenticated: PropTypes.bool,
}
