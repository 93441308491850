export class LaravelError extends Error {
  // continues is a flag to indicate if the error is a error that is begin handled by the system, but we must show this error to the user
  constructor(message, statusCode, errors, continues = false) {
    super(message)
    this.statusCode = statusCode
    this.continues = continues
    this.errors = errors
  }

  toString() {
    return `Message: ${this.message}, Status Code: ${this.statusCode}, Continues: ${this.continues}, Errors: ${JSON.stringify(this.errors)}`
  }
}

export class ExpectedError extends Error {
  constructor(message, statusCode, type, alreadyTranslatedMessage = false) {
    super(message)
    this.statusCode = statusCode
    this.type = type
    this.alreadyTranslatedMessage = alreadyTranslatedMessage
  }

  toString() {
    return `Message: ${this.message}, Status Code: ${this.statusCode}, Type: ${this.type}, AlreadyTranslatedMessage: ${this.alreadyTranslatedMessage}`
  }
}
