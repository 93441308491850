import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// Import all available translations files
import translationFR from 'core/locales/fr.locale.json'
import translationDE from 'core/locales/de.locale.json'
import { keyAppStorage, keyAppStorageLanguage } from './core/store/app.store'
import { apiService } from 'core/services/http.service'

// the translations
const resources = {
  fr: {
    translation: translationFR,
  },
  de: {
    translation: translationDE,
  },
}

const storedConfigs = localStorage.getItem(keyAppStorage)
const parsedConfigs = storedConfigs ? JSON.parse(storedConfigs) : {}
const lng = parsedConfigs[keyAppStorageLanguage] ?? 'fr' // default language

// Check https://www.i18next.com/overview/configuration-options for the bellow options
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      resources,
      lng: lng,
      keySeparator: false, // we do not use keys in form messages.welcome
      // with this make the language list as ['en', 'fr']
      fallbackLng: Object.keys(resources), // fallback language
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    },
    () => {
      // console.log('i18n initialized')
      apiService.setLanguage(lng)
    },
  )

export default i18n

// Example how to use it to change the language
// import React from 'react';
// import { useTranslation } from 'react-i18next';

// function MyComponent() {
//   const { t, i18n } = useTranslation();

//   const changeLanguage = (language) => {
//     i18n.changeLanguage(language);
//   };

//   return (
//     <div>
//       <h2>{t('Welcome to React')}</h2>
//       <button onClick={() => changeLanguage('en')}>English</button>
//       <button onClick={() => changeLanguage('fr')}>Français</button>
//     </div>
//   );
// }
